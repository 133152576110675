<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="addNewCompany" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="submit">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">ADD NEW COMPANY</h5>
          </div>
        </div>
        <div class="modal-body">
        <!--          FOR COMPANY NAME -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Company Name</label>
            <div class="col-sm-9">
              <input type="text" id="last_name" class="form-control form-control-sm" required v-model="newCompany.name">
            </div>
          </div>

          <!--          FOR COMPANY COUNTRY -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <select-with-search
                :key="'_select-search-country_' + newCompany.country_id"
                v-model="newCompany.country_id"
                :current-selected-item-id="newCompany.country_id"
                :search-placeholder="'Search Country...'"
                :key-item-value="'id'"
                :key-display-text="'name'"
                :items="[{
                  id : null,
                  name : '—— ALL COUNTRIES ——'
                }].concat(countryKeys)"
                :element-class="'form-control form-control-sm text-uppercase'"
              />
            </div>
          </div>

          <!--          FOR COMPANY MANAGEMENT -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Management</label>
            <div class="col-sm-9">
              <select-with-search
                :key="'_select-search-country_' + newCompany.management_id"
                v-model="newCompany.management_id"
                :current-selected-item-id="newCompany.management_id"
                :include-search-input="false"
                :key-item-value="'id'"
                :key-display-text="'name'"
                :items="[{
                  id : null,
                  name : '—— SELECT MANAGEMENT ——',
                  disabled : true
                }].concat(mngmntOfficeKeys)"
                :element-class="'form-control form-control-sm text-uppercase'"
              />
            </div>
          </div>



          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="closeModal">Close</button>
            <!--          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>-->
            <button type="submit" class="btn btn-sm btn-primary">Create New Record</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";
import {AlertService} from "@/services/AlertService";

export default {
  name : 'AddNewCompany',
  components: {SelectWithSearch},
  mixins : [requestLoaderMixin],
  data() {
    return {
      newCompany : {
        name : '',
        country_id : null,
        management_id : null
      }
    }
  },
  methods : {
    ...mapActions([
      'createCompany'
    ]),
    resetData() {
      this.newCompany = {
        name : '',
          country_id : null,
          management_id : null
      }
    },
    async submit() {
      this.showLoaderRequest('CREATING NEW COMPANY, PLEASE WAIT...');
      const createResponse = await this.createCompany(this.newCompany);
      if (createResponse.result === false) {
        return AlertService.errorAlert(createResponse.message, 'CREATING OF NEW COMPANY');
      }
      this.hideLoaderRequest();
      AlertService.successAlert('Created Successfully', 'CREATING OF NEW COMPANY');
      this.closeModal();
      this.resetData();
    },
    closeModal() {
      $('#addNewCompany').modal('hide');
    },
  },
  computed : {
    ...mapGetters([
      'countryKeys',
      'mngmntOfficeKeys',
    ])
  }
}
</script>

<style scoped>

</style>
