var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"data-backdrop":"static","data-keyboard":"false","id":"addNewCompany","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('form',{staticClass:"modal-content",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group row no-gutters mb-1"},[_c('label',{staticClass:"font-weight-bold col-sm-3 col-form-label",attrs:{"for":"last_name"}},[_vm._v("Company Name")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.name),expression:"newCompany.name"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"last_name","required":""},domProps:{"value":(_vm.newCompany.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newCompany, "name", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row no-gutters mb-1"},[_c('label',{staticClass:"font-weight-bold col-sm-3 col-form-label",attrs:{"for":"last_name"}},[_vm._v("Country")]),_c('div',{staticClass:"col-sm-9"},[_c('select-with-search',{key:'_select-search-country_' + _vm.newCompany.country_id,attrs:{"current-selected-item-id":_vm.newCompany.country_id,"search-placeholder":'Search Country...',"key-item-value":'id',"key-display-text":'name',"items":[{
                id : null,
                name : '—— ALL COUNTRIES ——'
              }].concat(_vm.countryKeys),"element-class":'form-control form-control-sm text-uppercase'},model:{value:(_vm.newCompany.country_id),callback:function ($$v) {_vm.$set(_vm.newCompany, "country_id", $$v)},expression:"newCompany.country_id"}})],1)]),_c('div',{staticClass:"form-group row no-gutters mb-1"},[_c('label',{staticClass:"font-weight-bold col-sm-3 col-form-label",attrs:{"for":"last_name"}},[_vm._v("Management")]),_c('div',{staticClass:"col-sm-9"},[_c('select-with-search',{key:'_select-search-country_' + _vm.newCompany.management_id,attrs:{"current-selected-item-id":_vm.newCompany.management_id,"include-search-input":false,"key-item-value":'id',"key-display-text":'name',"items":[{
                id : null,
                name : '—— SELECT MANAGEMENT ——',
                disabled : true
              }].concat(_vm.mngmntOfficeKeys),"element-class":'form-control form-control-sm text-uppercase'},model:{value:(_vm.newCompany.management_id),callback:function ($$v) {_vm.$set(_vm.newCompany, "management_id", $$v)},expression:"newCompany.management_id"}})],1)]),_c('div',{staticClass:"modal-footer"},[_c('button',{ref:"NewUser_close",staticClass:"btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_vm._v("Close")]),_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"submit"}},[_vm._v("Create New Record")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title w-100 text-center"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("ADD NEW COMPANY")])])])
}]

export { render, staticRenderFns }