<template>
  <div class="text-center">
    <div class="row no-gutters mb-2">
      <div class="col-sm-6">
        <h3 class="text-left ml-4">Company List</h3>
      </div>
      <div class="col-sm-6 text-right form-inline justify-content-end">
        <select class="form-control form-control-sm w-auto ml-3" v-model="params.active">
          <option value="">SELECT STATUS</option>
          <option :value="null">ALL</option>
          <option :value="1" class="bg-light">ACTIVE</option>
          <option :value="0" class="bg-light">INACTIVE</option>
        </select>
        <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#addNewCompany">
          <font-awesome-icon icon="plus-square" style="width: 25px"/>
          <small class="font-weight-bold px-2">
            ADD NEW COMPANY
          </small>
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setCompaniesToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setCompaniesToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setCompaniesToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-3 pb-2">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.search" placeholder="Search Company">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" rowspan="2" style="width: 1em" scope="col">#</th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('name')">
              COMPANY NAME
              <sorter-arrow
                :keyInList="'name'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
            </th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('country_id')">
              COUNTRY
              <sorter-arrow
                :keyInList="'country_id'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
            </th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('management_office_id')">
              <sorter-arrow
                :keyInList="'management_office_id'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
              MANAGEMENT
            </th>
            <th class="align-middle" colspan="2" scope="col">LAST AUDIT</th>
            <th style="width: 70px" rowspan="2" scope="col">ACTION</th>
          </tr>
          <tr>
            <th class="align-middle" scope="col">ADDRESS</th>
            <th class="align-middle" scope="col">DATE</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <tr v-for="(company, index) in companies">
              <td class="align-middle text-uppercase">{{index + 1 }}</td>
              <td class="align-middle text-uppercase">{{ company.name }}</td>
              <td class="align-middle text-uppercase">{{ company.country.name }}</td>
              <td class="align-middle text-uppercase">{{ company.management.code }}</td>
              <td class="align-middle text-uppercase">{{ company.last_audit.address }}</td>
              <td class="align-middle text-uppercase">{{ company.last_audit.date }}</td>
              <td class="align-middle py-1">
                <div class="dropdown dropright">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="companyOverview(company.id)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteSelected(company.id)"
                    >
                      <font-awesome-icon icon="trash" class="e-text-red"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
    <add-new-company />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";

import CompanyAuditMixin from "@/mixins/CompanyAuditMixin";
import AddNewCompany from "@/components/modules/ia/company/AddNewCompany.vue";
import SorterArrow from "@/components/common/SorterArrow.vue";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";
export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin, CompanyAuditMixin, SorterArrowMixin],
  components: {SorterArrow, AppLoading, AddNewCompany},
  name: 'VesselIndex',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        search:''
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions([
      'getCompaniesList',
      'deleteCompany'
    ]),
    // todo
    async companyOverview(vesselId) {
      // await this.$router.push({ name: 'VesselShow', params: { id: vesselId } }).catch(() => {})
      await this.$router.push({
        name: "VesselParticular",
        params: {id: vesselId},
      });

    },
    async deleteSelected(companyId){
      if(await AlertService.confirmDeleteAlert('Delete Company','Do you want to delete this company?','Yes, proceed')){
        let response = await this.deleteCompany(companyId);
        if(response) this.successAlert('Company Deleted Successfully','DELETE COMPANY');
      }
    },
    async setCompaniesToTable(page=1) {
      this.loading = true;
      this.params.page=page;
      await this.getCompaniesList(this.params);
      this.loading = false;
    },
    runCallBack(param) {
      this.params.orderBy = param.orderBy;
      this.params.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setCompaniesToTable(1);
    }
  },
  computed: {
    ...mapGetters([
      'pagination',
      'companies',
    ])
  },
  async created() {
    this.setCompanies = _.debounce(this.setCompaniesToTable, 750)
    await this.setCompaniesToTable();

  },
  watch: {
    'params.active'() {
      this.setCompanies()
    },
    'params.search'(){
      this.setCompanies();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped lang="scss">
.e-tbl  thead tr th {
  border: 1px solid #b1b1b1;
}
</style>
