import {mapActions, mapGetters} from "vuex";

export default {
  methods : {
    ...mapActions([
      'getCountryListKeys',
      'getMngmtOfficeKeys',
    ]),
  },
  computed : {
    ...mapGetters([
      'state_loading'
    ])
  },
  async created() {
    await this.getCountryListKeys();
    await this.getMngmtOfficeKeys();
  }
}
